<template>
  <base-section id="expertises">
    <base-section-heading title="Expertises"> </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal>
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        color: "primary",
        dark: true,
        title: "Microsoft .NET Core / Framework",
        icon: "mdi-dot-net"
      },
      {
        color: "primary",
        dark: true,
        title: "Development met C#",
        icon: "mdi-language-csharp"
      },
      {
        color: "primary",
        dark: true,
        title: "Vue.js",
        icon: "mdi-vuejs"
      },
      {
        color: "primary",
        dark: true,
        title: "Azure",
        icon: "mdi-microsoft-azure"
      },
      {
        color: "primary",
        dark: true,
        title: "Azure DevOps",
        icon: "mdi-microsoft-azure-devops"
      },
      {
        color: "primary",
        dark: true,
        title: "SCRUM / Agile",
        icon: "mdi-shape-circle-plus"
      }
    ]
  })
};
</script>
